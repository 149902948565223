let resumeData = {
    "imagebaseurl":"https://blackphlox.github.io/",
    "name": "Mikkel Luja Rasmussen",
    "role": "I am currently a student at ITU Copenhagen",
    "contacts":[
      {
        "name":"LinkedIn",
        "url":"https://linkedin.com/in/luja",
        "urlName":"linkedin.com/in/luja"
      },
      {
        "name":"GitHub",
        "url":"https://github.com/BlackPhlox",
        "urlName":"github.com/BlackPhlox"
      },
      {
        "name":"Twitter",
        "url":"https://twitter.com/darkphlox",
        "urlName":"twitter.com/darkphlox"
      },
      {
        "name":"Email",
        "url":"mailto:theluja@gmail.com",
        "urlName":"theluja@gmail.com"
      }
    ],
    "roleDescription": " I like helping and working with all parts of the software stack and I like to tinker with new technologies and learn from writing small prototypes to fully fledged programs.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/luja",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/BlackPhlox",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"https://twitter.com/darkphlox",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"I am currently a student at The IT University of Copenhagen and pursuing my Bachelor in Software Development from here. I am currently diving deeper into the great range of capabilities that is rust and what it has to offer in terms of it-security and game development.",
    "address":"Denmark",
    "website":"theluja@gmail.com",
    "education":[
      {
        "UniversityName":"IT-University of Copenhagen",
        "specialization":"Bachelor in Software Development",
        "MonthOfPassing":"August",
        "YearOfPassing":"2021",
        "Achievements":""
      },
      {
        "UniversityName":"VUC Lyngby",
        "specialization":"Mathemathics",
        "MonthOfPassing":"August",
        "YearOfPassing":"2017",
        "Achievements":""
      },
      {
        "UniversityName":"Erhvervsskolen Nordsjælland Helsingør",
        "specialization":"Technological",
        "MonthOfPassing":"May",
        "YearOfPassing":"2017",
        "Achievements":"Communication- og It A, Specialization: English A, Technology: Design & Production (Hardware development) A, Design B, Psychology C & Multimedia C"
      },
      {
        "UniversityName":"Helsingør Lilleskole",
        "specialization":"Primary (FS10)",
        "MonthOfPassing":"August",
        "YearOfPassing":"2014",
        "Achievements":""
      }
    ],
    "work":[
      {
        "CompanyName":"Red Barnet Denmark",
        "specialization":"Student Assistant",
        "MonthOfLeaving":"November 2019 - Now",
        "YearOfLeaving":"",
        "Achievements":""
      },
      {
        "CompanyName":"Coop Denmark",
        "specialization":"Service worker",
        "MonthOfLeaving":"August 2014 - April",
        "YearOfLeaving":"2015",
        "Achievements":""
      }
    ],
    "exp":[
      {
        "CompanyName":"Helsingør Lilleskole Gambia Charity Concert",
        "specialization":"Organizer",
        "MonthOfLeaving":"January",
        "YearOfLeaving":"2014",
        "Achievements":`
          Being one of the organizers of the Gambia Charity Concert at 
          Elværket, Helsingør. All of the profit went 
          to our friendship-school in Fulla Kunda. Which we traveled to later that year.`,
        "img":"images/gambia.jpeg",
        "linkText": "Link",
        "Link":"https://sn.dk/Helsingoer/Unge-stoetter-Gambia-med-kaempe-koncert/artikel/379845",
        "imgText":"The ceremony for which the first bricks of the new meeting room were laid, the entire building was paid for from all the profit of the concert that had been held."
      },
      {
        "CompanyName":"Developing the next generation system for IoT security",
        "specialization":"Cryptera",
        "MonthOfLeaving":"Spring",
        "YearOfLeaving":"2019",
        "Achievements":`
          In the course "Second Year Project: Software Development 
          in Large Teams" we, The Scrumble Bees, worked together 
          with Cryptera which is a company that specialises in security.
          Together using SCRUM, we began the development of a new security 
          service, FirmwareGuard+, that allows their customers to do secure provisioning remotely 
          as a service without requiring IoT devices to be transported to get firmware loaded.`,
        "linkText": "FirmwareGuard+",
        "img": "https://i.imgur.com/WqE5i72.png",
        "img2": "https://www.cryptera.com/wp-content/uploads/2019/09/illustration_01.jpg",
        "Link":"https://www.cryptera.com/products/firmwareguard/firmwareguard-customized-microchip/",
        "imgText":`
          After the completion of five sprints, lasting two weeks each, 
          the development and the working product was then passed on to the 
          developers at Cryptera to be further developed.
          As can be seen by the posts, the service has a great potential to
          provide extensive benefits for companies in the industry of developing IoT devices.`,
        "iframe1":"https://www.linkedin.com/embed/feed/update/urn:li:share:6501729710777733120",
        "iframe1height":"460px",
        "iframe2":"https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6575663406077091840",
        "iframe2height":"420px",
      }
    ],
    "skillsDescription":"A showcase of my current skillset, click to expand to see more specifics for each skill.",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"JavaScript",
        "desc":`
          I have worked with JavaScript for three years and have developed both web-apps, servers
           and websites, with one of them being this site, developed using React framework.`,
        "subskills": ["node.js","Reactjs","p5.js","matter.js"]
      },
      {
        "skillname":"Java",
        "desc":`
          Started to work with Java three years ago, when I began to tinker with 
          the developing of hardware user interface using an Arduino and the Java library 
          Firmata together with Processing.
        `,
        "subskills": ["JavaFX","Processing"]
      },
      {
        "skillname":"SQL",
        "desc":`
          I have worked with both MySQL and ASP.NET in courses and have used it to 
          develop multiple web-apps using the technology. 
        `,
        "subskills":["MySQL","ASP.NET"]
      },
      {
        "skillname":"C#"
      },
      {
        "skillname":"Graphics",
        "desc":`
          I have created graphics and done video editing since Primary. In gymnasium I
          focused on Communication and IT, which have made me highly
          skilled in graphics design and video editing.
        `,
        "subskills": ["Figma","Adobe After Effects","Adobe Illustrator","Photoshop"]
      },
      {
        "skillname":"Sound Editing"
      },
      {
        "skillname":"3D Modelling",
        "desc":`
          Having design in gymnasium provided the interest of modeling in 3D, 
          which lead to me learning 3D modeling software, 3D physics and rendering.
        `,
        "subskills": ["Cinema 4D","SketchUp"]
      }
    ],
    "portfolio":[
      {
        "name":"Bevy Config Cam",
        "description":"A camera plugin for the bevy game engine",
        "imgurl":"images/portfolio/config_cam.jpg",
        "url":"https://github.com/BlackPhlox/bevy_config_cam"
      },
      {
        "name":"wt-editor-cli",
        "description":"Windows Terminal Editor for power users",
        "imgurl":"images/portfolio/wte.gif",
        "url":"https://github.com/BlackPhlox/wt-editor-cli"
      },
      {
        "name":"Bevy Midi",
        "description":"A midi plugin for the bevy game engine",  
        "imgurl":"images/portfolio/bevy_midi.svg",
        "url":"https://github.com/BlackPhlox/bevy_midi"
      },
      {
        "name":"AsciiEngine",
        "description":"AsciiEngine 2.5D Topdown Game Engine using Processing and Box2D",
        "imgurl":"images/portfolio/ascii.gif",
        "url":"https://github.com/BlackPhlox/AsciiEngine"
      }
    ]/*,
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]*/
  }
  
  export default resumeData