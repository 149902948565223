import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
export default class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">

         <div className="row education">

            <div className="three columns header-col">
               <h1><span>Education</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.education && resumeData.education.map((item,index)=>{
                  return(
                    <div className="row item" key={"edu-"+index}>
                       <div className="twelve columns">
                          <h3>{item.UniversityName}</h3>
                          <p className="info">
                          {item.specialization}
                          <span>&bull;</span> <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em></p>
                          <p>
                          {item.Achievements}
                          </p>
                       </div>
                    </div>
                  )
                })
              }
            </div>
         </div>
        <div className="row work">
            <div className="three columns header-col">
               <h1><span>{"Work"}</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.work && resumeData.work.map((item,index) => {
                  return(
                    <div className="row item" key={"work-"+index}>
                       <div className="twelve columns">
                          <h3>{item.CompanyName}</h3>
                          <p className="info">
                          {item.specialization}
                          <span>&bull;</span> <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em></p>
                          <p>
                          {item.Achievements}
                          </p>
                       </div>

                    </div>

                  )
                })
              }
            </div> 
         </div>

         <div className="row work">
            <div className="three columns header-col">
               <h1><span>{"Experiences"}</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.exp && resumeData.exp.map((item,index) => {
                  return(
                    <div className="row item" key={"xp-"+item.Achievements+"-"+index}>
                       <div className="twelve columns">
                          <h3>{item.CompanyName}</h3>
                          <p className="info">
                          {item.specialization}
                          <span>&bull;</span> <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em></p>
                          <p>
                          {item.Achievements}{" "}
                          <a href={item.Link}>
                            {item.linkText}
                            
                          </a>
                          </p>
                          {item.img?<img className="expImg" src={item.img}></img>:""}
                          {item.iframe1 ? <div className="iframe-container"><iframe allowFullScreen src={item.iframe1}></iframe></div> : ""}
                          <p>
                            {item.imgText}
                          </p>
                          {item.img2?<img className="expImg" src={item.img2}></img>:""}
                          {item.iframe2 ? <div className="iframe-container"><iframe allowFullScreen src={item.iframe2}></iframe></div> : ""}
                       </div>
                    </div>

                  )
                })
              }
            </div> 
         </div>


         <div className="row skill">

            <div className="three columns header-col">
               <h1><span>Skills</span></h1>
            </div>

            <div className="nine columns main-col">

               <p>
               {resumeData.skillsDescription}
               </p>

   				<div className="bars">

   				   <ul className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item, index) => {
                    const skill = (
                      <li>
                      <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                      </span><em>{item.skillname}</em>
                      <p className={"skillDisc"}>
                        {item.desc}
                      </p>              
                      </li>
                    );//<ul className={"expandContainer"}><div className={"expandIcon"}></div></ul>
                    return(
                        <Collapsible trigger={skill} key={item.skillname.toLowerCase()} triggerDisabled={item.subskills ? false:true}>
                        {item.subskills ?<div className={"spacer"}></div>:<div/>}
                        {
                          item.subskills ?
                          item.subskills.map((subskill) => {
                            return(
                              <li className={"subskill"} key={"skill-"+subskill+"-"+index}>
                              <span className={`bar-expand ${subskill.toLowerCase()}`}>
                              </span><em>{subskill}</em>
                              </li>
                            )
                          })
                          :
                          <div/>
                        }
                        </Collapsible>
                      
                    )
                  }
                )}
   					</ul>

   				</div>

   			</div>

         </div>

      </section>
    );
  }
}