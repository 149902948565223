import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Feel free to contact me for any work, questions or suggestions below
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                {
                  resumeData.contacts && resumeData.contacts.map((item,index)=>{
                    return (
                      <div key={index}>
                        <h4>{item.name} : <a href={item.url}>{item.urlName}</a></h4>
                      </div>                      
                    )
                  })
                }
              </div>
            </aside>
          </div>
        </section>
        );
  }
}
